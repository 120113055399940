:export {
  white: #ffffff;
  grey100: #fbfbfb;
  grey200: #f3f5f8;
  grey300: #e7ecf3;
  grey400: #d0d8e4;
  grey500: #919bab;
  grey600: #6a7381;
  grey700: #313b46;
  grey800: #0f161e;
  green50: #e9fcf4;
  green100: #ccf4dd;
  green200: #7edcaf;
  green300: #06cb8a;
  green400: #05b279;
  green500: #00756a;
  green600: #00665b;
  green700: #00554b;
  blue50: #eef8ff;
  blue100: #c7e2ff;
  blue200: #75acff;
  blue300: #5d9aee;
  blue400: #4385e0;
  blue500: #226ace;
  blue600: #1859b5;
  blue700: #0f4897;
  petrol50: #e3fbfb;
  petrol100: #c5f5f5;
  petrol200: #65e2ea;
  petrol300: #00bcc9;
  petrol400: #00a5ae;
  petrol500: #107191;
  petrol600: #005e7f;
  petrol700: #004561;
  purple50: #fbf4ff;
  purple100: #edd5ff;
  purple200: #d8adff;
  purple300: #b46efe;
  purple400: #9e4ff0;
  purple500: #8736dc;
  purple600: #6509bf;
  purple700: #430080;
  red50: #fff1f1;
  red100: #ffd2cf;
  red200: #fa8373;
  red300: #f1674f;
  red400: #e54d33;
  red500: #cc2c11;
  red600: #af0200;
  red700: #990000;
  orange50: #fffadf;
  orange100: #fff0b8;
  orange200: #ffdb73;
  orange300: #ffb420;
  orange400: #ffa740;
  orange500: #ff8f0d;
  orange600: #ad4800;
  orange700: #943e00;
}

:export {
  heading1-large: 64px;
  heading1-large-tablet: 48px;
  heading1-large-mobile: 40px;
  heading1: 40px;
  heading1-mobile: 32px;
  heading2: 32px;
  heading2-tablet-mobile: 28px;
  heading3: 24px;
  subheading: normal;
  text-main-body: 24px;
  text-body: 20px;
  text-secondary: 16px;
  caption1: 20px;
  caption2: 20px;
  caption3: 16px;
}

:export {
  heading1-large: 48px;
  heading1-large-tablet: 36px;
  heading1-large-mobile: 32px;
  heading1: 32px;
  heading1-tablet: 28px;
  heading1-mobile: 24px;
  heading2: 24px;
  heading2-tablet-mobile: 20px;
  heading3: 18px;
  subheading: 16px;
  text-main-body: 16px;
  text-body: 14px;
  text-secondary: 13px;
  caption1: 14px;
  caption2: 13px;
  caption3: 11px;
}

:export {
  xsmall: 4px;
  small: 8px;
  seminormal: 12px;
  normal: 16px;
  semilarge: 24px;
  large: 32px;
  xlarge: 48px;
}

:export {
  white: #ffffff;
  grey100: #fbfbfb;
  grey200: #f3f5f8;
  grey300: #e7ecf3;
  grey400: #d0d8e4;
  grey500: #919bab;
  grey600: #6a7381;
  grey700: #313b46;
  grey800: #0f161e;
  green50: #e9fcf4;
  green100: #ccf4dd;
  green200: #7edcaf;
  green300: #06cb8a;
  green400: #05b279;
  green500: #00756a;
  green600: #00665b;
  green700: #00554b;
  blue50: #eef8ff;
  blue100: #c7e2ff;
  blue200: #75acff;
  blue300: #5d9aee;
  blue400: #4385e0;
  blue500: #226ace;
  blue600: #1859b5;
  blue700: #0f4897;
  petrol50: #e3fbfb;
  petrol100: #c5f5f5;
  petrol200: #65e2ea;
  petrol300: #00bcc9;
  petrol400: #00a5ae;
  petrol500: #107191;
  petrol600: #005e7f;
  petrol700: #004561;
  purple50: #fbf4ff;
  purple100: #edd5ff;
  purple200: #d8adff;
  purple300: #b46efe;
  purple400: #9e4ff0;
  purple500: #8736dc;
  purple600: #6509bf;
  purple700: #430080;
  red50: #fff1f1;
  red100: #ffd2cf;
  red200: #fa8373;
  red300: #f1674f;
  red400: #e54d33;
  red500: #cc2c11;
  red600: #af0200;
  red700: #990000;
  orange50: #fffadf;
  orange100: #fff0b8;
  orange200: #ffdb73;
  orange300: #ffb420;
  orange400: #ffa740;
  orange500: #ff8f0d;
  orange600: #ad4800;
  orange700: #943e00;
}

:export {
  color-default: #00756a;
  color-white: #ffffff;
  color-font-primary: #313b46;
  color-font-secondary: #6a7381;
  color-font-primary-light: #ffffff;
  color-font-primary-dark: #313b46;
  color-border-default: #d0d8e4;
  color-success: #05b279;
  color-success-button: #7edcaf;
  color-success-background: #e9fcf4;
  color-success-outline: #ccf4dd;
  color-danger: #cc2c11;
  color-danger-background: #fff1f1;
  color-danger-outline: #ffd2cf;
  color-caution: #ad4800;
  color-caution-button: #ffdb73;
  color-caution-background: #fffadf;
  color-caution-outline: #fff0b8;
  color-informational: #226ace;
  color-informational-background: #eef8ff;
  color-informational-outline: #c7e2ff;
  color-warning: #ffb420;
  color-ai: #8736dc;
  color-ai-background: #fbf4ff;
  color-ai-outline: #edd5ff;
  color-beta-primary: #107191;
  color-beta-secondary: #c5f5f5;
  color-neutral: #6a7381;
  color-muted: #f3f5f8;
  background-color: #ffffff;
  background-color-secondary: #f3f5f8;
  background-color-dark: #313b46;
  color-outline: #226ace;
  color-outline-focus: #6a7381;
  color-input-default: #d0d8e4;
  color-input-muted: #919bab;
  color-input-placeholder: #6a7381;
  color-input-label-optional: #919bab;
  color-input-helper-default: #6a7381;
  color-dismiss-default: #919bab;
}

:export {
  white: #ffffff;
  grey100: #fbfbfb;
  grey200: #f3f5f8;
  grey300: #e7ecf3;
  grey400: #d0d8e4;
  grey500: #919bab;
  grey600: #6a7381;
  grey700: #313b46;
  grey800: #0f161e;
  green50: #e9fcf4;
  green100: #ccf4dd;
  green200: #7edcaf;
  green300: #06cb8a;
  green400: #05b279;
  green500: #00756a;
  green600: #00665b;
  green700: #00554b;
  blue50: #eef8ff;
  blue100: #c7e2ff;
  blue200: #75acff;
  blue300: #5d9aee;
  blue400: #4385e0;
  blue500: #226ace;
  blue600: #1859b5;
  blue700: #0f4897;
  petrol50: #e3fbfb;
  petrol100: #c5f5f5;
  petrol200: #65e2ea;
  petrol300: #00bcc9;
  petrol400: #00a5ae;
  petrol500: #107191;
  petrol600: #005e7f;
  petrol700: #004561;
  purple50: #fbf4ff;
  purple100: #edd5ff;
  purple200: #d8adff;
  purple300: #b46efe;
  purple400: #9e4ff0;
  purple500: #8736dc;
  purple600: #6509bf;
  purple700: #430080;
  red50: #fff1f1;
  red100: #ffd2cf;
  red200: #fa8373;
  red300: #f1674f;
  red400: #e54d33;
  red500: #cc2c11;
  red600: #af0200;
  red700: #990000;
  orange50: #fffadf;
  orange100: #fff0b8;
  orange200: #ffdb73;
  orange300: #ffb420;
  orange400: #ffa740;
  orange500: #ff8f0d;
  orange600: #ad4800;
  orange700: #943e00;
}

:export {
  color-default: #00756a;
  color-white: #ffffff;
  color-font-primary: #313b46;
  color-font-secondary: #6a7381;
  color-font-primary-light: #ffffff;
  color-font-primary-dark: #313b46;
  color-border-default: #d0d8e4;
  color-success: #05b279;
  color-success-button: #7edcaf;
  color-success-background: #e9fcf4;
  color-success-outline: #ccf4dd;
  color-danger: #cc2c11;
  color-danger-background: #fff1f1;
  color-danger-outline: #ffd2cf;
  color-caution: #ad4800;
  color-caution-button: #ffdb73;
  color-caution-background: #fffadf;
  color-caution-outline: #fff0b8;
  color-informational: #226ace;
  color-informational-background: #eef8ff;
  color-informational-outline: #c7e2ff;
  color-warning: #ffb420;
  color-ai: #8736dc;
  color-ai-background: #fbf4ff;
  color-ai-outline: #edd5ff;
  color-beta-primary: #107191;
  color-beta-secondary: #c5f5f5;
  color-neutral: #6a7381;
  color-muted: #f3f5f8;
  background-color: #ffffff;
  background-color-secondary: #f3f5f8;
  background-color-dark: #313b46;
  color-outline: #226ace;
  color-outline-focus: #6a7381;
  color-input-default: #d0d8e4;
  color-input-muted: #919bab;
  color-input-placeholder: #6a7381;
  color-input-label-optional: #919bab;
  color-input-helper-default: #6a7381;
  color-dismiss-default: #919bab;
}

:export {
  -2: 0 -2px 5px rgba(51, 62, 73, 0.1);
  2: 0 2px 5px rgba(51, 62, 73, 0.1);
  6: 0 1px 5px rgba(51, 62, 73, 0.14), 0 4px 10px rgba(51, 62, 73, 0.08);
  8: 0px 6px 17px rgba(51, 62, 73, 0.18), 0px 3px 10px rgba(51, 62, 73, 0.18);
  12: 0 3px 10px rgba(51, 62, 73, 0.18), 0 6px 17px rgba(51, 62, 73, 0.18);
  24: 0 10px 24px rgba(51, 62, 73, 0.1), 0 7px 11px rgba(51, 62, 73, 0.2);
}

:export {
  heading1-large: 64px;
  heading1-large-tablet: 48px;
  heading1-large-mobile: 40px;
  heading1: 40px;
  heading1-mobile: 32px;
  heading2: 32px;
  heading2-tablet-mobile: 28px;
  heading3: 24px;
  subheading: normal;
  text-main-body: 24px;
  text-body: 20px;
  text-secondary: 16px;
  caption1: 20px;
  caption2: 20px;
  caption3: 16px;
}

:export {
  color-default: #00756a;
  color-white: #ffffff;
  color-font-primary: #313b46;
  color-font-secondary: #6a7381;
  color-font-primary-light: #ffffff;
  color-font-primary-dark: #313b46;
  color-border-default: #d0d8e4;
  color-success: #05b279;
  color-success-button: #7edcaf;
  color-success-background: #e9fcf4;
  color-success-outline: #ccf4dd;
  color-danger: #cc2c11;
  color-danger-background: #fff1f1;
  color-danger-outline: #ffd2cf;
  color-caution: #ad4800;
  color-caution-button: #ffdb73;
  color-caution-background: #fffadf;
  color-caution-outline: #fff0b8;
  color-informational: #226ace;
  color-informational-background: #eef8ff;
  color-informational-outline: #c7e2ff;
  color-warning: #ffb420;
  color-ai: #8736dc;
  color-ai-background: #fbf4ff;
  color-ai-outline: #edd5ff;
  color-beta-primary: #107191;
  color-beta-secondary: #c5f5f5;
  color-neutral: #6a7381;
  color-muted: #f3f5f8;
  background-color: #ffffff;
  background-color-secondary: #f3f5f8;
  background-color-dark: #313b46;
  color-outline: #226ace;
  color-outline-focus: #6a7381;
  color-input-default: #d0d8e4;
  color-input-muted: #919bab;
  color-input-placeholder: #6a7381;
  color-input-label-optional: #919bab;
  color-input-helper-default: #6a7381;
  color-dismiss-default: #919bab;
}

@keyframes placeholderSkeleton {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

/* Fonts */
/* Borders */
/* Border-radius */
/* Dropdowns */
/* Inputs */
/* Transitions */
/* Dropdown sizes */
/* Modal */
.illustrated-group-item {
  color: var(--color-font-primary, #313b46);
}

.illustrated-group-item {
  border-radius: 8px;
  border: solid 1px var(--color-input-default, #d0d8e4);
  transition: border-color 0.2s ease-in;
  background-color: white;
}

.illustrated-group-item.error {
  border: solid 1px var(--color-danger, #cc2c11);
}

.illustrated-group-item.warning {
  border: solid 1px var(--color-warning, #ffb420);
}

.group {
  display: inline-flex;
  flex-wrap: nowrap;
  padding: 0;
  flex-grow: 0;
}

.group.responsive {
  display: flex;
  flex-basis: 100%;
}

.group.responsive > * {
  min-width: 0px;
}

.group.responsive > * input {
  min-width: 0px;
}

.group.horizontal {
  flex-direction: row;
}

.group.horizontal.responsive .group-item:not(.illustrated-group-item) {
  flex: 1;
}

.group.horizontal input.group-item {
  width: auto;
}

.group.horizontal.rounded .group-item:not(.first-item) {
  margin-left: -1px;
}

.group.horizontal.rounded .group-item[data-warning='true'], .group.horizontal.rounded .group-item[data-error='true'] {
  z-index: 1;
}

.group.horizontal.rounded .group-item:focus-within:not([data-readonly='true']) {
  z-index: 2;
}

.group.horizontal.rounded .group-item:not(.last-item),
.group.horizontal.rounded .group-item:hover:not(.last-item),
.group.horizontal.rounded .group-item:focus:not(.last-item),
.group.horizontal.rounded .group-item:active:not(.last-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.group.horizontal.rounded .group-item:not(.last-item)[data-input-type='select'] > label > div, .group.horizontal.rounded .group-item:not(.last-item)[data-input-type='tree-select'] > label > div,
.group.horizontal.rounded .group-item:hover:not(.last-item)[data-input-type='select'] > label > div,
.group.horizontal.rounded .group-item:hover:not(.last-item)[data-input-type='tree-select'] > label > div,
.group.horizontal.rounded .group-item:focus:not(.last-item)[data-input-type='select'] > label > div,
.group.horizontal.rounded .group-item:focus:not(.last-item)[data-input-type='tree-select'] > label > div,
.group.horizontal.rounded .group-item:active:not(.last-item)[data-input-type='select'] > label > div,
.group.horizontal.rounded .group-item:active:not(.last-item)[data-input-type='tree-select'] > label > div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.group.horizontal.rounded .group-item:not(.last-item)[data-input-type='autocomplete'] > div > div,
.group.horizontal.rounded .group-item:hover:not(.last-item)[data-input-type='autocomplete'] > div > div,
.group.horizontal.rounded .group-item:focus:not(.last-item)[data-input-type='autocomplete'] > div > div,
.group.horizontal.rounded .group-item:active:not(.last-item)[data-input-type='autocomplete'] > div > div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.group.horizontal.rounded .group-item:not(.last-item).illustrated-group-item > div > *,
.group.horizontal.rounded .group-item:hover:not(.last-item).illustrated-group-item > div > *,
.group.horizontal.rounded .group-item:focus:not(.last-item).illustrated-group-item > div > *,
.group.horizontal.rounded .group-item:active:not(.last-item).illustrated-group-item > div > * {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.group.horizontal.rounded .group-item:not(.first-item),
.group.horizontal.rounded .group-item:hover:not(.first-item),
.group.horizontal.rounded .group-item:focus:not(.first-item),
.group.horizontal.rounded .group-item:active:not(.first-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.group.horizontal.rounded .group-item:not(.first-item)[data-input-type='select'] > label > div, .group.horizontal.rounded .group-item:not(.first-item)[data-input-type='tree-select'] > label > div,
.group.horizontal.rounded .group-item:hover:not(.first-item)[data-input-type='select'] > label > div,
.group.horizontal.rounded .group-item:hover:not(.first-item)[data-input-type='tree-select'] > label > div,
.group.horizontal.rounded .group-item:focus:not(.first-item)[data-input-type='select'] > label > div,
.group.horizontal.rounded .group-item:focus:not(.first-item)[data-input-type='tree-select'] > label > div,
.group.horizontal.rounded .group-item:active:not(.first-item)[data-input-type='select'] > label > div,
.group.horizontal.rounded .group-item:active:not(.first-item)[data-input-type='tree-select'] > label > div {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.group.horizontal.rounded .group-item:not(.first-item)[data-input-type='autocomplete'] > div > div,
.group.horizontal.rounded .group-item:hover:not(.first-item)[data-input-type='autocomplete'] > div > div,
.group.horizontal.rounded .group-item:focus:not(.first-item)[data-input-type='autocomplete'] > div > div,
.group.horizontal.rounded .group-item:active:not(.first-item)[data-input-type='autocomplete'] > div > div {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.group.horizontal.rounded .group-item:not(.first-item).illustrated-group-item > div > *,
.group.horizontal.rounded .group-item:hover:not(.first-item).illustrated-group-item > div > *,
.group.horizontal.rounded .group-item:focus:not(.first-item).illustrated-group-item > div > *,
.group.horizontal.rounded .group-item:active:not(.first-item).illustrated-group-item > div > * {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.group.vertical {
  flex-direction: column;
}

.group.vertical.rounded .group-item.first-item,
.group.vertical.rounded .group-item:hover.first-item,
.group.vertical.rounded .group-item:focus.first-item,
.group.vertical.rounded .group-item:active.first-item {
  margin-top: 0;
}

.group.vertical.rounded .group-item.last-item,
.group.vertical.rounded .group-item:hover.last-item,
.group.vertical.rounded .group-item:focus.last-item,
.group.vertical.rounded .group-item:active.last-item {
  margin-bottom: 0;
}

.group.vertical.rounded .group-item:not(.last-item),
.group.vertical.rounded .group-item:hover:not(.last-item),
.group.vertical.rounded .group-item:focus:not(.last-item),
.group.vertical.rounded .group-item:active:not(.last-item) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.group.vertical.rounded .group-item:not(.first-item),
.group.vertical.rounded .group-item:hover:not(.first-item),
.group.vertical.rounded .group-item:focus:not(.first-item),
.group.vertical.rounded .group-item:active:not(.first-item) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.group .group-item > :not(dialog) > [data-role='illustrated-input'] input ~ svg:not(:last-child) {
  display: none;
}

.illustrated-group-item > div {
  border-radius: 8px;
  width: 42px;
  height: 42px;
}

.illustrated-group-item > div button {
  width: 42px;
  height: 42px;
}

.illustrated-group-item > div button:focus {
  box-shadow: none !important;
}

.illustrated-group-item:not(.last-item) > div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.illustrated-group-item:not(.first-item) > div {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
